// i18next-extract-mark-ns-start support-and-claims

import {Article} from 'components/Article';
import {SEO} from 'components/SEO';
import {graphql} from 'gatsby';
import {Link, Trans, useI18next} from 'gatsby-plugin-react-i18next';
import React from 'react';
import {getPolicyUrl} from 'utils/helpers';

const Page: React.FC = () => {
  const {t, language} = useI18next();
  return (
    <Article wide>
      <SEO
        path="legal-notice"
        title={t('Support and Customer Service')}
        description={t(
          'Information related to Customer Support, Customer Service, complaints to the Banco de España, and incidents and exercise of rights regarding the protection of personal data.'
        )}
      />
      <Trans parent="h1">Support and Claims</Trans>
      <Trans parent="p">
        Information related to Customer Support, Customer Service, complaints to the Banco de
        España, and incidents and exercise of rights regarding the protection of personal data.
      </Trans>
      <Trans parent="h2">Support and Customer Service</Trans>
      <Trans parent="p">
        <b>Support:</b> In case you have questions, doubts, requests, suggestions or incidents of
        any kind, you can write to us at the following email address{' '}
        <a href="mailto:support@monei.com">support@monei.com</a>.
      </Trans>
      <Trans parent="p">
        In case of issues, we recommend that you find a solution first by writing to the email{' '}
        <a href="mailto:support@monei.com">support@monei.com</a>. If you are unable to resolve the
        incident or if you are not satisfied with the response obtained, you can file a written
        complaint or complaint with the <b>MONEI Customer Service</b>, which will resolve it, in
        accordance with the rules and principles established in the regulations on transparency and
        customer protection of financial services within a maximum period of 15 working days for
        complaints related to payment services and 1 month for all other matters, unless you are not
        a consumer, because in that case the deadline The resolution will be 2 months.
      </Trans>
      <Trans parent="p">
        You can also send us your written complaint or complaint, together with a copy of your ID
        card or identity document, through the following channels:
      </Trans>
      <Trans parent="p">
        <b>Postal Mail:</b> Calle Palestina 1, Entreplanta, Of. E 29007 - Málaga España
      </Trans>
      <Trans parent="p">
        <b>Email:</b> <a href="mailto:SAC@monei.com">SAC@monei.com</a>
        <br />
        In this case, you must have an electronic signature in accordance with the requirements set
        out in Law 59/2003, of December 19, in order to be able to correctly identify yourself.
      </Trans>
      <Trans parent="p">
        <b>Web form:</b> by filling out <Link to="/customer-defence-form">this form</Link>. <br />
        In case of disagreement with the resolution of the MONEI Customer Service, or if the maximum
        periods have elapsed since the submission without obtaining a resolution, you can contact
        the complaints service of the Banco de España, for which you have a period of 1 year from
        the filing of the complaint with the Entity.
      </Trans>
      <Trans parent="p">
        It is an essential requirement to be able to file a complaint with the Banco de España
        complaints service, to have submitted, in advance, a complaint to the MONEI Customer
        Service.
      </Trans>
      <Trans parent="p">
        The address of the Banco de España complaints service is: <br />
        Bank of Spain's Entity Conduct branch Department Alcalá Street, No. 48 28014, Madrid
      </Trans>
      <ul>
        <Trans parent="li">
          <a
            href="https://www.bde.es/bde/es/secciones/servicios/Particulares_y_e/Servicio_de_Recl/"
            target="_blank"
            rel="noreferrer noopenner">
            Bank of Spain's Entity Conduct Department
          </a>
        </Trans>
        <Trans parent="li">
          <a
            href="https://www.bde.es/bde/es/secciones/servicios/Particulares_y_e/Servicio_de_Recl/"
            target="_blank"
            rel="noreferrer noopenner">
            Bank Customer Portal — Complaints and Complaints
          </a>
        </Trans>
        <Trans parent="li">
          <a
            href="https://www.bde.es/bde/es/secciones/servicios/Particulares_y_e/Servicio_de_Recl/"
            target="_blank"
            rel="noreferrer noopenner">
            Download the claim form
          </a>
        </Trans>
      </ul>
      <Trans parent="h2">Exercise of Data Protection rights</Trans>
      <Trans parent="p">
        To exercise your rights of access, rectification, deletion, limitation to processing,
        opposition to processing, data portability, not to be subject to automated individual
        decisions or to revoke the consent granted, protected by the General Data Protection
        Regulation EU 2016/679 (GDPR), you can write to the MONEI Data Protection Officer, both by
        post (Calle Palestina 1, Entreplanta, Of. E, 29007 - Málaga) or by email (to the address{' '}
        <a href="mailto:au+rgpd@monei.com">au+rgpd@monei.com</a>), attaching in any case a copy of
        your ID card or equivalent document proving your identity.
      </Trans>
      <Trans parent="p">
        <a
          href={getPolicyUrl('client-defence-v1.pdf', language)}
          target="_blank"
          rel="noreferrer noopenner">
          Consult SAC regulations
        </a>
      </Trans>
    </Article>
  );
};

export default Page;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "support-and-claims"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
