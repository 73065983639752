import styled from 'styled-components';

export const Article = styled.article<{wide?: boolean}>`
  max-width: ${(props) => (props.wide ? 800 : 624)}px;
  margin: 60px auto 0;
  padding: 0 20px;
  position: relative;
  @media (max-width: 768px) {
    margin: 25px auto 0;
  }
`;
